import IP_ADDRESS from "../config";

async function graphData(deviceId, fromDate, toDate) {

    if(fromDate !== '' && fromDate !== ""){
      fromDate = fromDate.unix();
    }
    if(toDate !== ''){
      toDate = toDate.unix();
    }
  
      try {
        const response = await fetch(IP_ADDRESS + `/device/graphData?deviceId=${deviceId}&fromDate=${fromDate}&toDate=${toDate}&accessToken=${localStorage.getItem("myStorage")}`, {
          method: 'GET', // HTTP method
        });
      
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
    
        const data = await response.json();
    
        return data;
      } catch (error) {
        console.error('Error fetching device data:', error);
        throw error; // Rethrow the error to handle it in the calling code if needed
      }
    }
    
    export default graphData;
    