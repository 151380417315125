import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import React from 'react';
import Login from "./Pages/LoginScreen";
import AuthCheckComponent from './Pages/AuthCheckComponent';

const App = () => {
 
  return (
    <Router>
     <Routes>
      <Route path="/home" element = {<AuthCheckComponent />} />
      <Route path="/login" element = {<Login />} />
      <Route path="/logout" element = {<Navigate to = "/login" />} />
      <Route path="*" element = {<Navigate to = "/home" />} />
     </Routes>
    </Router>
  );
};

export default App;