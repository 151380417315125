// Home.js
import React, { useEffect, useState } from 'react';
import DeviceData from '../API functions/GetDeviceData';
import graphData from '../API functions/GetGraphData';
import TabComponent from '../Components/TabComponent';
import TableComponent from '../Components/TableComponent';
import navComponent from '../Components/NavComponent';
import BasicDateTimePicker from '../Components/DateTimeComponent';
import SimpleLineChart from '../API functions/GenerateLineGraph';
import styled from 'styled-components';
import ListOfDevices from '../API functions/GetListOfDevices';
import { ListGroup } from 'react-bootstrap';
import TopNavbarComponent from '../Components/TopNavbarComponent';

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Home = () => {
    const [apiData, setApiData] = useState({
        content: [],
        totalPages: 0,
        currentPage: 0,
      });
      const [pageNo, setPage] = useState(0);
    
      const [deviceId, setDeviceId] = useState('');
      const [deviceName, setDeviceName] = useState('No devices mapped');
        
      const [fromDateTemp, setFromDateTemp] = useState('');
      const [toDateTemp, settoDateTemp] = useState('');
    
      const [fromDate, setFromDate] = useState('');
      const [toDate, settoDate] = useState('');
      const [dateError, setdateError] = useState('');
    
      const [graphApiData, setGraphData] = useState('');

      const [deviceList, setDeviceList] = useState([]);
      const [deviceError, setDeviceError] = useState('');
    
      const handleFilterSubmit = () => {
        settoDate(toDateTemp);
        setFromDate(fromDateTemp);
        setIsVisible(!isVisible);
        if (!fromDate || !toDate || fromDateTemp <= toDateTemp) {
          setdateError('');
      } else {
        setdateError('End date cannot be before start date');
      }

      if (deviceId){
        setDeviceError('');
      } else {
        setDeviceError('Please select a device to view the data')
      }
      };

      function changePageNo(isNext) {
        setPage((prevPageNo) => {
          const newPageNo = isNext ? prevPageNo + 1 : prevPageNo - 1;
          return newPageNo;
        });
      }

function setPageNo(isLast){
        setPage((prevPageNo) => {
          const newPageNo = isLast ? apiData.totalPages -1 : 0;
          return newPageNo;
        });
      }
    
      useEffect(() => {
        const fetchData = async () => {
          try {
            let deviceData = await DeviceData(deviceId, fromDate, toDate, pageNo);
            setApiData(deviceData);
          } catch (error) {
            console.log("Something went wrong");
          }
        };
    
        fetchData();
      }, [deviceId, pageNo, fromDate, toDate]);
    
      useEffect(() => {
        const fetchData = async () => {
          try {
            let deviceData = await graphData(deviceId, fromDate, toDate);
            setGraphData(deviceData);
          } catch (error) {
            console.log("Something went wrong");
          }
        };
    
        fetchData();
      }, [deviceId, fromDate, toDate]);

        useEffect(() => {
          const fetchDeviceList = async () => {
            try {
              let devices = await ListOfDevices(localStorage.getItem("myStorage"));
              setDeviceList(devices.deviceList);
              setDeviceId(devices.deviceList[0].deviceId)
              setDeviceName(devices.deviceList[0].deviceName)
            } catch (error) {
              console.log("Something went wrong");
            }
          };
      
          fetchDeviceList();
        }, []);

        const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const setDeviceIdAndName = (device) => {
    setDeviceId(device.deviceId)
    setDeviceName(device.deviceName)
  }

  return (
    <>
    <Container className='main-container'>
    {navComponent()}
    <TopNavbarComponent 
    fromDate={fromDate}
    toDate={toDate}
    deviceName={deviceName}
    toggleVisibility={toggleVisibility}
    />
    <div style={{ display: isVisible ? 'flex' : 'none', position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 999, animation: `${isVisible ? 'fade-in 0.3s ease-in-out forwards' : ''}` }}>
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', backgroundColor: 'white', padding: '20px', borderRadius: '5px', minWidth: '300px', height: '75vh', overflow: 'auto', position: 'relative', zIndex: 1000, animation: `${isVisible ? 'slide-up 0.3s ease-in-out forwards' : ''}` }}>
    <div>
    <BasicDateTimePicker
    fromDate={fromDateTemp}
    toDate={toDateTemp}
    onFromChange={setFromDateTemp}
    onToChange={settoDateTemp}/>
    {dateError && <p style={{ color: 'red' }}>{dateError}</p>}
      {deviceError && <p>{deviceError}</p>}
    <ListGroup style={{marginTop: '1vh', maxWidth: ''}}>
      {deviceList.map((device, index) => (
        <ListGroup.Item
          key={index}
          active={device.deviceId === deviceId}
          onClick={() => setDeviceIdAndName(device)}
          style={{
            backgroundColor: device.deviceId === deviceId ? '#2c3e50' : 'inherit',
            color: device.deviceId === deviceId ? 'white' : 'black',
            border: `1px solid ${device.deviceId === deviceId ? '#2c3e50' : 'lightgray'}`
          }}
        >
          {device.deviceName}
        </ListGroup.Item>
      ))}
    </ListGroup>
    </div>
      <button className = "button-style" onClick={handleFilterSubmit}>Show data</button>
      </div>
      </div>
    <TabComponent>
    {graphApiData.length > 0 ? (
            <SimpleLineChart className='graph-container' sensorData={graphApiData} />
          ) : (
            <div>No data to show for the current filters.</div>
          )}
    {apiData.content.length > 0 ? (
            <TableComponent apiData={apiData} setPageNo={setPageNo} changePageNo={changePageNo} deviceId={deviceId} fromDate={fromDate} toDate={toDate}/>
          ) : (
            <div>No data to show for the current filters.</div>
          )}
  </TabComponent>
    </Container>
      </>
  );
};

export default Home;
