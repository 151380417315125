import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import validateSession from "../API functions/ValidateSession";
import Home from "./Home";

const AuthCheckComponent = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        validateSession(localStorage.getItem("myStorage"))
            .then(response =>{
                if (response.status === 200){
                    setIsAuthenticated(true);
                }
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error:', error);
                setIsLoading(false);
            });
    }, []);

    if (isLoading) {
        // Display loading spinner or message while waiting for validation
        return <div>Loading...</div>;
    }

    if (isAuthenticated) {
        // If authenticated, render the Home component
        return <Home />;
    } else {
        // If not authenticated, navigate to the login page
        return <Navigate to="/login" />;
    }
}

export default AuthCheckComponent;
