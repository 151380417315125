import IP_ADDRESS from "../config";

const authenticateUser = async (username, password) => {
    const url = IP_ADDRESS + '/auth/login'; // Replace this with your API endpoint
    const data = {
      // Your POST request body data
      email: username,
      password: password,
    };
    let responseData;
    try {
      let response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any additional headers if required
        },
        body: JSON.stringify(data),
      });

    responseData = await response.json()
      if (response.status == 200){
      localStorage.setItem('myStorage', responseData.accessToken)
      return {
          status: response.status, 
          challengeName: responseData.challengeName,
          session: responseData.session,
          accessToken: responseData.accessToken,
          idToken: responseData.idToken,
          refreshToken: responseData.refreshToken
      }
    }
    else {
      return {
        status: response.status,
        error: responseData.error
      }
    }
    
    } catch (error) {
      console.error('Error:', error);
    }    
  };

export default authenticateUser;