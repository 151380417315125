import moment from 'moment';
import styled from 'styled-components';

const TopNavbar = styled.nav`
  height: 10vh;
  background-color: whitesmoke;
  display: flex;
  align-items: flex-end;
  justify-content: center
`;

const formatDate = (timestamp) => {
  return moment.unix(timestamp).format('DD/MM/YY HH:mm'); // Convert timestamp to dd/mm/yy hh:mm format
};

const TopNavbarComponent = ({ fromDate, toDate, deviceName, toggleVisibility }) => {
  let fromDateString = '';
  let toDateString = '';

  console.log("This devince name was set" + deviceName)

  if(fromDate !== '' && fromDate !== ""){
    fromDate = fromDate.unix();
  }
  if(toDate !== ''){
    toDate = toDate.unix();
  }

  console.log(fromDate)
  // Convert fromDate and toDate to human-readable format
  if (fromDate) {
    fromDateString = formatDate(fromDate);
  }
  if (toDate) {
    toDateString = formatDate(toDate);
  }

  // If both fromDate and toDate are blank, show "Past hour"
  if (!fromDate && !toDate) {
    fromDateString = moment().subtract(1, 'hour').format('DD/MM/YY HH:mm');
    toDateString = moment().format('DD/MM/YY HH:mm');
  } else if (!fromDate) { // If fromDate is empty, set it to toDate - 1 hour
    fromDateString = moment.unix(toDate).subtract(1, 'hour').format('DD/MM/YY HH:mm');
  } else if (!toDate) { // If toDate is empty, set it to fromDate + 1 hour
    toDateString = moment.unix(fromDate).add(1, 'hour').format('DD/MM/YY HH:mm');
  }

  return (
    <TopNavbar>
        <div style={{display: 'flex', flexDirection: 'row', maxWidth: '100vw'}}>
        <button style={{textWrap: 'nowrap', overflow: 'auto'}} className='button-style' onClick={toggleVisibility}>{fromDateString} - {toDateString}</button>
      <button className='button-style' onClick={toggleVisibility}>{deviceName}</button>
        </div>
         </TopNavbar>
  );
};

export default TopNavbarComponent;
