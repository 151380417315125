import IP_ADDRESS from "../config";

async function DeviceData(deviceId, fromDate, toDate, pageNo) {

    if(fromDate !== '' && fromDate !== ""){
      fromDate = fromDate.unix();
      console.log("this is " + fromDate)

    }
    if(toDate !== ''){
      toDate = toDate.unix();
    }
    try {
      const response = await fetch(IP_ADDRESS + `/device/data?deviceId=${deviceId}&fromDate=${fromDate}&toDate=${toDate}&pageNo=${pageNo}&accessToken=${localStorage.getItem("myStorage")}`, {
        method: 'GET', // HTTP method
      });
  
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
  
      const data = await response.json();
  
      return {
        content: data.content,
        totalPages: data.totalPages,
        isLast: data.last,
        isFirst: data.first,
        currentPage: data.pageable.pageNumber,
      };
    } catch (error) {
      console.error('Error fetching device data:', error);
      throw error; // Rethrow the error to handle it in the calling code if needed
    }
  }
  
  export default DeviceData;