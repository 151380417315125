import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

export default function BasicDateTimePicker({fromDate, toDate, onFromChange, onToChange}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div>
    <DateTimePicker 
      label="From"
      value={fromDate}
      onChange={onFromChange}
      format="DD/MM/YYYY hh:mm A"
      sx={{
        '& input': {
          color: '#2c3e50',
        },
        '& .MuiInputLabel-root': {
          color: '#2c3e50',
        },
        '& .MuiInputBase-root': {
          backgroundColor: 'white',
          border: '1px solid #2c3e50',
          maxWidth: '100%',
        },
        '& .MuiSvgIcon-root': {
          color: '#2c3e50',
        },
        "& .MuiInputBase-input": {
          marginRight: 0
        }
      }}
    />
    </div>
    <DateTimePicker
      label="To"
      value={toDate}
      onChange={onToChange}
      format="DD/MM/YYYY hh:mm A"
      sx={{
        '& input': {
          color: '#2c3e50',
        },
        '& .MuiInputLabel-root': {
          color: '#2c3e50',
        },
        '& .MuiInputBase-root': {
          backgroundColor: 'white',
          border: '1px solid #2c3e50',
          maxWidth: '100%',
        },
        '& .MuiSvgIcon-root': {
          color: '#2c3e50',
        },
      }}
    />
</LocalizationProvider>
  );
}