// TableComponent.js
import React from 'react';
import { usePagination, useSortBy, useTable } from 'react-table';
import downloadFile from '../API functions/DownloadDeviceData';

const TableComponent = ({ apiData, setPageNo, changePageNo, deviceId, fromDate, toDate }) => {
  let dataContent = apiData.content;

  const columns = React.useMemo(
    () =>
      dataContent && dataContent.length > 0
        ? [
            { Header: 'DateTime', accessor: 'dateTime' },
            ...Object.keys(dataContent[0]?.data || {}).map((key) => ({
              Header: key,
              accessor: `data.${key}`,
              Cell: ({ value }) => (typeof value === 'object' ? JSON.stringify(value) : value)
            }))
          ]
        : [],
    [dataContent]
  );
  
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page} =
        useTable({ 
          columns,
          data: dataContent
        },
        useSortBy,
        usePagination
        );

  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th {...header.getHeaderProps(header.getSortByToggleProps())} key={header.id}>
                  {header.render('Header')}
                  {header.isSorted && <span>{header.isSortedDesc ? '⬇' : '⬆'}</span>}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={row.id}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()} key={cell.id}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    <div className="btn-container">
        <button className='button-style' disabled={apiData.currentPage === 0} onClick={() => setPageNo(0)}>First</button>
        <button className='button-style' disabled={apiData.currentPage === 0} onClick={() => changePageNo(0)}>Previous</button>
        <span>{apiData.currentPage + 1} of {apiData.totalPages}</span>
        <button className='button-style' disabled={apiData.currentPage >= apiData.totalPages - 1} onClick={() => changePageNo(1)}>Next</button>
        <button className='button-style' disabled={apiData.currentPage >= apiData.totalPages - 1} onClick={() => setPageNo(1)}>Last</button>
        <button className='button-style' onClick={() => downloadFile(deviceId, fromDate, toDate)}>Download</button>
      </div>
    </>
  );
};

export default TableComponent;