import IP_ADDRESS from "../config";

async function downloadFile(deviceId, fromDate, toDate) {

    if(fromDate !== '' && fromDate !== ""){
        console.log("this is " + fromDate)

        fromDate = fromDate.unix();
      }
      if(toDate !== ''){
        toDate = toDate.unix();
      }
    try {
        const response = await fetch(IP_ADDRESS + `/device/downloadData?deviceId=${deviceId}&fromDate=${fromDate}&toDate=${toDate}&accessToken=${localStorage.getItem("myStorage")}`);
        const blob = await response.blob();

        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'deviceData.csv');
        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
    } catch (error) {
        console.error('Error downloading file:', error);
    }
}

export default downloadFile;