import IP_ADDRESS from "../config";

const ListOfDevices = async (accessToken) => {
    const url = IP_ADDRESS + '/device/list';
    const data = {
      // Your POST request body data
      accessToken: accessToken
    };
    let responseData;
    try {
      let response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any additional headers if required
        },
        body: JSON.stringify(data),
      });

    responseData = await response.json();

    return {
        deviceList: responseData,
        status: response.status
    }
    } catch (error) {
      console.error('Error:', error);
    }    
  };

export default ListOfDevices;