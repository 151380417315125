import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

function navComponent() {
  return (
    <Navbar className="card" style={{maxWidth: '100vw'}}>
      <Container>
        <Navbar.Brand>Welcome to IoT dashboard</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Nav className="me-auto">
          </Nav>
          <Nav className="ml-auto">
            <Nav.Link href="/login" onClick={() => {localStorage.removeItem("myStorage")}}>Logout</Nav.Link>
          </Nav>
      </Container>
    </Navbar>
  );
}

export default navComponent;