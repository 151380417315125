import React, { useState, useEffect } from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { Form } from 'react-bootstrap';

export default function SimpleLineChart({ sensorData }) {
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [dateTimeArray, setDateTimeArray] = useState([]);
  const [seriesConfig, setSeriesConfig] = useState([]);

  const handleKeySelect = (key) => {
    const isSelected = selectedKeys.includes(key);
    if (isSelected) {
      setSelectedKeys(selectedKeys.filter((k) => k !== key));
    } else {
      setSelectedKeys([...selectedKeys, key]);
    }
  };

  useEffect(() => {
    if (sensorData.length > 0) {
      const keys = Object.keys(sensorData[0].data);
      setSeriesConfig(keys.map((key) => ({
        dataKey: key,
        label: key,
        showMark: false,
        area: false
      })));

      const selectedData = sensorData.map((item) => {
        const selected = {};
        selectedKeys.forEach((key) => {
          selected[key] = item.data[key];
        });
        return selected;
      });
      setSelectedValues(selectedData);

      const dateTimeArray = sensorData.map((item) => item.dateTime);
      setDateTimeArray(dateTimeArray);
    }
  }, [selectedKeys, sensorData]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '65vh', alignContent: 'center', justifyContent: 'center' }}>
        <div>
        <ul style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', justifyItems: 'center', alignContent: 'center', listStyle: 'none', gap: '1vw', maxWidth: '100vw', height: '100%' }}>
  {sensorData.length > 0 &&
    Object.keys(sensorData[0].data).map((key) => (
      <li key={key}>
        <Form.Check
          type="checkbox"
          id={key}
          label={key}
          checked={selectedKeys.includes(key)}
          onChange={() => handleKeySelect(key)}
        />
      </li>
    ))}
</ul>
        </div>
        <div style={{display: 'flex', height: '100%', maxWidth: '100vw', alignContent: 'center'}} className='card'>
          <LineChart sx={{color: "white"}}
            dataset={selectedValues}
            xAxis={[{ scaleType: 'point', data: dateTimeArray, tickNumber: 2 }]}
            series={seriesConfig}
            slotProps={{
              legend: {hidden: true}
            }}
          />
        </div>
      </div>
  );
}


