import IP_ADDRESS from "../config";

const createNewPasswordFirstTime = async (username, password, session) => {
    const url = IP_ADDRESS + '/auth/changePassword';
    const data = {
      email: username,
      newPassword: password,
      session: session
    };
    let responseData;
    try {
      let response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any additional headers if required
        },
        body: JSON.stringify(data),
      });

    responseData = await response.json();
    return {
        accessToken: responseData.accessToken,
        idToken: responseData.idToken,
        refreshToken: responseData.refreshToken
    }
    } catch (error) {
      console.error('Error:', error);
    }    
  };

export default createNewPasswordFirstTime;