import React, { useState } from 'react';
import './LoginScreen.css';
import authenticateUser from '../API functions/AuthenticateUser';
import { useNavigate } from 'react-router-dom';
import createNewPasswordFirstTime from '../API functions/CreateNewPasswordFirstTime';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  let navigate = useNavigate();

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const isFormValid = () => {
    return username.trim() !== '' && password.trim() !== '';
  };
  const [error, setError] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    authenticateUser(username, password)
    .then(response => {
      if (response.status == 200){
        setError('');
      
        if(response.challengeName == "NO_CHALLENGE"){
            navigate('/home')
        }
        else if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
            let newPassword = '';
            let isValidPassword = false;
          
            while (!isValidPassword) {
              newPassword = prompt('New password needs to be set on initial login. Please enter your new password:');
          
              // Password validation regex
              const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+}{|":?><,./;'\[\]\\=-]).{8,}$/;
          
              if (passwordRegex.test(newPassword)) {
                isValidPassword = true;
              } else {
                alert('Invalid password! Password must be at least 8 characters long and contain at least one number, one lowercase letter, one uppercase letter, and one special character.');
              }
            }
          
            // Assuming user sets the new password through prompt
            console.log('New password set:', newPassword);
            createNewPasswordFirstTime(username, newPassword, response.session)
            .then(response => {
                console.log('new password resposne - ', response)
            })
          }
        } else {
          setError('Invalid username or password');
        }
        console.log(response)
    })
  };

  return (
    <div class="outer-container">
    <div class="title-container">
        <h3>Login</h3>
    </div>
    <div class="form-container">
        <form onSubmit={handleSubmit}>
            <div>
                <label for="username" class="hidden"></label>
                <input id="username" type="text" placeholder="Username" value={username} onChange={handleUsernameChange} />
            </div>
            <div>
                <label for="password" class="hidden"></label>
                <input id="password" type="password" placeholder="Password" value={password} onChange={handlePasswordChange} />
            </div>
            <div>
                <button type="submit" disabled={!isFormValid()} class="login-button">Login</button>
                {error && <p style={{ color: 'red' }}>{error}</p>}
            </div>
        </form>
    </div>
</div>
  );
}

export default Login;